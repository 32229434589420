import { defineStore } from 'pinia'
import { ROLES_CODES } from './../constants/roles'

interface State {
  isAuth: boolean
  roleCode: ROLES_CODES | null
}

const useAuthStore = defineStore('auth', {
  state: (): State => ({
    isAuth: false,
    roleCode: null,
  }),
  getters: {
    isGuest: ({ roleCode }) => roleCode === ROLES_CODES.GUEST,
    isPartner: ({ roleCode }) => roleCode === ROLES_CODES.PARTNER,
    isPartnerManager: ({ roleCode }) => roleCode === ROLES_CODES.PARTNER_MANAGER,
    isSuperAdmin: ({ roleCode }) => roleCode === ROLES_CODES.SUPER_ADMIN,
    isCEO: ({ roleCode }) => roleCode === ROLES_CODES.CEO,
  },
  actions: {
    setIsAuth(value: boolean) {
      this.isAuth = value
    },
    setRole(roleCode: ROLES_CODES) {
      this.roleCode = roleCode
    },
    clearRole() {
      this.roleCode = null
    },
  },
})

export default useAuthStore
